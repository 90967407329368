<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <div class="columns is-vcentered">
        <div class="column">
          <a href="https://cdefholding.nl/" target="_blank">
            <figure class="image is-64x64 is-horizontal-center mt-3">
              <img src="@/assets/images/cyberdigma.png">
            </figure>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #fff;
  padding: 30px;
}

/* Center cyberdigma logo */
.is-horizontal-center {
  margin: auto;
}
</style>