<template>
  <nav
    class="navbar has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link :to="{name: 'home'}">
        <img
          src="@/assets/images/logo-cyberdigma.png"
          style="max-height: 60px;"
        >
      </router-link>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <transition
          name="fade"
          mode="out-in"
        >
          <LoggedInProfile
            v-if="profile"
            key="profile"
          />
          <router-link
            v-else
            key="login"
            :to="{name: 'my-account-login'}"
            v-text="$t('Components.MainSiteElements.LoggedInProfile.Login')"
            class="button is-success"
            :class="{'is-loading' : !appStartupLoadReady}"
            :disabled="!appStartupLoadReady"
          ></router-link>
        </transition>
      </div>
    </div>
  </nav>
</template>

<script>
const LoggedInProfile = () => import(/* webpackChunkName: "frontendTopbar" */ '@/components/MainSiteElements/LoggedInProfile')

import { mapGetters, mapState } from 'vuex'
export default {
  name: 'frontent-TopBar',

  components: {
    LoggedInProfile
  },

  computed: {
    ...mapState(['appStartupLoadReady']),
    ...mapState('profileStore', ['profile']),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";

.navbar {
  padding: 5px $gap;
  align-items: stretch;
  display: flex;
  .navbar-end {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-item {
    display: flex;
    align-items: center;
  }
}
</style>